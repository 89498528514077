import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At PCMH Restore Health in association with Being Positive foundation and Molecular Solutions Care Health pride ourselves in taking new and upcoming talents under our wings and providing them with a supervised environment to grow in. Education has always been a pillar of our institution. Learning and not just limited to that amidst bound pages but the kind that elevates and helps everyone grow. Our team is equipped with members with a large and varied skill set, with many years of experience guiding and strengthening dedicated students.`}</p>
    <p>{`​`}</p>
    <p>{`The internship program has been conceived and construed to ensure that students are provided experiences that aren’t available in the classroom. We would like our interns to be a part of our team and our projects. Restore Health is a growing institution and we welcome our interns to be a part of this growth participate in a multitude of activities that are constantly taking place. We encourage research and pride ourselves in able mentors, who are willing to invest time in training students in the basics of research followed for guidance in pursuing a study on their own or being a part of the studies that occur in our center.`}</p>
    <p>{`Opportunities`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Case presentations`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Research training`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Journal Club`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Poster presentation`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Visit to partner organizations`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Participation in clinical practice`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Learn about laboratory work`}</p>
      </li>
    </ol>
    <p>{`and additional supportive roles and opportunities`}</p>
    <p>{`We look for highly motivated students with interests in advancing their skill set and stepping beyond what their zone of comfort. Things move at a fast pace at our organization and your potential mentors will be busy but will set apart a dedicated time for you, hence we request that if you are interested in the program that you set apart time dedicated towards the internship program. We understand the obligations of your academic pursuits and will be understanding and flexible to a degree.`}</p>
    <p>{`​`}</p>
    <p>{`While we are apt and experienced in training medical students, we encourage students from other streams to work with us, especially those from other science-related fields. We intend for this program to set about a pattern of generating highly unique, motivated and successful individuals with skill sets not normally available in the Indian Medical System or conventional systems of education, pursuing high functioning and impact-driven careers.`}</p>
    <p>{`​`}</p>
    <p>{`How to apply?`}</p>
    <p>{`Send us your CV, a letter of motivation with your goals and ambitions to `}<a parentName="p" {...{
        "href": "mailto:abraham.rahul@gmail.com."
      }}>{`abraham.rahul@gmail.com.`}</a>{` If there are slots available and we feel there would be mutual benefit in our alliance we’ll set up an interview to discuss timings and mutually agreed upon goals. A checklist of targets will be created to be approved by the management of the institution. A guiding mentor will be chosen and your internship starts!`}</p>
    <p>{`​`}</p>
    <p>{`At the end of completing your preset goals and completing the designated time for your internship program, we will provide a certificate and a letter of recommendation/excellence based on your performance.`}</p>
    <p>{`​`}</p>
    <p>{`If you have any queries, do not hesitate to write to us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      